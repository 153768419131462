
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns2e04508084d3ab8f09f0dbd65bc813a1en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '2e04508084d3ab8f09f0dbd65bc813a1', flattenObject(ns2e04508084d3ab8f09f0dbd65bc813a1en_US), true, true);
        
            import ns2e04508084d3ab8f09f0dbd65bc813a1en from './en.yaml'
            i18n.addResourceBundle('en', '2e04508084d3ab8f09f0dbd65bc813a1', flattenObject(ns2e04508084d3ab8f09f0dbd65bc813a1en), true, true);
        
        export default '2e04508084d3ab8f09f0dbd65bc813a1';
    