// @flow
import React, { Component } from 'react'
import cn from 'classnames'
import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import MediaQuery from 'react-responsive'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'
// import CheckMark from './CheckMark'

type Props = {
    step: number,
    description?: string,
};

type StepProps = {
    t: Function,
    theme: { [string]: mixed },
    step: number,
    currentStep: number,
}

const Step = ({ theme, step, currentStep }: StepProps) => {
    const completed = currentStep > step
    const current = currentStep === step
    const classes = cn({ [styles.current]: current, [styles.completed]: completed })
    const isActive = current ? { backgroundColor: theme.activeColor } : {}
    return (
        <div className={styles.stepLabel}>
            <span className={classes} style={{ backgroundColor: theme.inactiveColor, ...isActive }}>
            </span>
        </div>
    )
}

class Steps extends Component<Props> {
    static defaultProps = {
        step: 1
    }

    render () {
        const { t, theme, step, description } = this.props
        return (
            <div className={cn(styles.container, this.props.className)}>
                <MediaQuery maxWidth={767}>
                    {
                        description &&
                        <p className={styles.description}>
                            {description}
                        </p>
                    }
                <div className={styles.stepsContainer}>
                    {[1, 2, 3].map(item => <Step t={t} key={item} step={item} currentStep={step} theme={theme} />)}
                </div>
                </MediaQuery>

                <MediaQuery minWidth={767}>
                    <div className={styles.stepsContainer} style={{
                        '--active-text-color': theme.titleColor,
                        '-check-color': theme.checkColor
                    }}>
                        <div className={cn(styles.title, {
                            [styles.active]: step === 1,
                            [styles.done]: step > 1
                        })}>
                          {/* <CheckMark color={theme.checkColor} />  */}
                          Contact Information
                        </div>
                        <div className={cn(styles.title, {
                            [styles.active]: step === 2,
                            [styles.done]: step > 2
                        })}>
                          {/* <CheckMark color={theme.checkColor} /> */}
                           New Vehicle Selection
                        </div>
                        <div className={cn(styles.title, {
                            [styles.active]: step === 3,
                            [styles.done]: step > 3
                        })}>
                          {/* <CheckMark color={theme.checkColor} />  */}
                          New Vehicle Details
                        </div>

                        <div
                            className={styles.border}
                        >
                            <div style={{
                                height: '100%',
                                borderRadius: '8px',
                                backgroundColor: theme.titleColor,
                                width: `${33.3 * step}%`
                            }} />
                        </div>
                    </div>
                </MediaQuery>
            </div>
        )
    }
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Steps))
