// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'

import supportedThemes from './themes/__supportedThemes.js'

import RegisterIncentives from 'components/RegisterIncentives'
import RegisterForm from 'components/RegisterForm'

import transDomain from './translations/index.translations'
import MainLogo from '../MainLogo'

import withTheme from 'hoc/withTheme'

import type { Theme } from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = {
    theme: Theme
}

function Hero (props: Props) {
    const { theme, children } = props
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode

    return (
        <div
            className={styles.mainContainer}
            style={{ '--primary-color': theme.primaryColor }}
        >
            <div className={styles.bgWrapper} style={{
                backgroundImage: isIE11 && `url(${theme.headerBg})`,
                '--hero-bg': `url(${theme.background})`,
                '--hero-bg-tablet': `url(${theme.backgroundTablet})`,
                '--hero-bg-mobile': `url(${theme.backgroundMobile})`,
            }}>
                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <MainLogo />
                    </div>

                    <div className={styles.formContainer}>
                        <RegisterForm />
                    </div>
                </div>

                <div className={styles.registerIncentivesContainer}>
                    <RegisterIncentives />
                </div>
            </div>
             <div className={styles.body}>
                {children}
             </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Hero))
