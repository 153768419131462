// @flow
import * as React from 'react'
import cn from 'classnames'

import { withTranslation } from 'react-i18next'
import RegisterVoucherValue from 'components/RegisterVoucherValue'
import CashbackAndRates from 'components/CashbackAndRates'
import withTheme from 'hoc/withTheme'

import { Store } from 'Store.js'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function RegisterIncentives (props) {
    const { state } = React.useContext(Store)
    const incentives = state.campaign.incentives.manufacturer

    return (
        <div className={cn(styles.inner, {
            [styles.withNoincentives]: !incentives
        })} style={{
            '--label-color': props.theme.labelColor
        }}>

            <div className={styles.incentivesWrapper}>
                <RegisterVoucherValue />
                <CashbackAndRates />
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterIncentives))
