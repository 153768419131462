// @flow

import * as React from 'react'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes'

type Props = {
    y: string,
    x: string,
    width: string,
    primaryColor: string,
    secondaryColor: string,
    eventColor: string,
    logoRefCallback?: (elm: React.Ref) => void,
}

const EventLogo = React.forwardRef(function (props: Props, ref: React.Ref) {
    return (
      <svg
        width={301}
        height={180}
        viewBox="0 0 301 180"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
        ref={ref}
      >
        <title>{"EVENT_LOGO"}</title>
        <g fill="none" fillRule="evenodd">
          <path
            d="M26.26.38C11.78.38 0 12.18 0 26.685v126.843c0 14.504 11.78 26.304 26.26 26.304h248.383c14.48 0 26.26-11.8 26.26-26.304V26.685c0-14.505-11.78-26.305-26.26-26.305H26.26z"
            fill="#FEFEFE"
          />
          <path
            d="M274.956 167.512H26.044c-8.223 0-14.89-6.653-14.89-14.86V122.63h278.691v30.023c0 8.207-6.666 14.86-14.889 14.86m0-160.506H26.044C15.544 7.006 7 15.533 7 26.012v126.64c0 10.48 8.543 19.006 19.044 19.006h248.912c10.5 0 19.044-8.526 19.044-19.006V26.012c0-10.48-8.543-19.006-19.044-19.006"
            fill={props.primaryColor}
          />
          <path
            d="M31.733 103.182c-3.267-3.612-4.9-9.436-4.9-17.47v-7.847h15.923v9.996c0 3.986 1.287 5.979 3.864 5.979 1.444 0 2.45-.42 3.015-1.262.565-.84.847-2.256.847-4.25 0-2.615-.315-4.78-.942-6.493-.628-1.712-1.43-3.144-2.402-4.297-.975-1.152-2.718-2.942-5.23-5.372l-6.972-6.912c-5.403-5.232-8.103-11.024-8.103-17.377 0-6.85 1.586-12.065 4.758-15.647 3.171-3.58 7.805-5.372 13.898-5.372 7.286 0 12.531 1.915 15.735 5.745 3.203 3.83 4.805 9.825 4.805 17.984H49.54l-.094-5.512c0-1.059-.299-1.9-.895-2.522-.598-.622-1.43-.935-2.496-.935-1.258 0-2.2.343-2.827 1.028-.63.686-.943 1.62-.943 2.803 0 2.615 1.508 5.324 4.523 8.127l9.422 8.968c2.198 2.118 4.02 4.126 5.465 6.026 1.444 1.9 2.606 4.143 3.486 6.726.879 2.585 1.319 5.652 1.319 9.202 0 7.91-1.46 13.905-4.381 17.983-2.92 4.08-7.742 6.119-14.463 6.119-7.349 0-12.658-1.806-15.923-5.418M89.32 61.001c1.96 0 3.4-.359 4.318-1.078.916-.719 1.518-1.75 1.803-3.096.284-1.344.427-3.267.427-5.769 0-3.376-.38-5.815-1.14-7.316-.758-1.5-2.277-2.251-4.554-2.251H88.37V61h.949zM72.333 26.858h21.542c6.515 0 11.165 2.08 13.95 6.238 2.782 4.16 4.175 10.24 4.175 18.244 0 8.005-1.234 14.039-3.701 18.103-2.467 4.066-6.896 6.097-13.286 6.097h-6.642v31.892H72.333V26.858zM137.067 56.687c2.266 0 3.4-2.44 3.4-7.317 0-2.125-.095-3.72-.284-4.784-.189-1.062-.536-1.796-1.039-2.204-.504-.406-1.228-.61-2.172-.61h-3.778v14.915h3.873zm-20.4-29.829H141.6c3.966 0 7.036.891 9.209 2.673 2.171 1.782 3.636 4.284 4.391 7.504.755 3.222 1.133 7.333 1.133 12.335 0 4.566-.599 8.13-1.794 10.693-1.197 2.565-3.275 4.347-6.234 5.347 2.456.501 4.234 1.72 5.337 3.658 1.1 1.939 1.652 4.566 1.652 7.88l-.188 30.484h-15.867V75.916c0-2.251-.441-3.69-1.323-4.315-.882-.624-2.487-.938-4.816-.938v36.77h-16.433V26.857z"
            fill="#FEFEFE"
          />
          <path
            fill="#FEFEFE"
            d="M162.167 107.432h17.5V26.858h-17.5zM186.667 107.432V26.858h16.637l7.426 38.552V26.858h15.603v80.574h-15.79l-8.085-40.334v40.334zM238.737 102.48c-2.826-4.078-4.237-10.26-4.237-18.543V49.372c0-7.41 1.644-13.016 4.935-16.815 3.29-3.798 8.194-5.699 14.712-5.699 5.276 0 9.373 1.06 12.291 3.176 2.917 2.118 4.936 5.2 6.053 9.248 1.117 4.05 1.676 9.249 1.676 15.602h-15.644V47.69c0-1.93-.264-3.424-.791-4.484-.528-1.058-1.475-1.588-2.84-1.588-3.042 0-4.563 1.994-4.563 5.98v38.861c0 2.43.31 4.267.932 5.512.62 1.247 1.706 1.868 3.259 1.868 1.55 0 2.637-.621 3.259-1.868.62-1.245.93-3.083.93-5.512V68.338l-4.283-7.195h19.554v46.71h-6.424l-2.7-6.727c-2.857 4.983-7.264 7.474-13.223 7.474-5.773 0-10.072-2.04-12.896-6.12"
          />
          <path
            fill="#000"
            d="M28 156.477h4.667v-21.019H28zM41.345 143.778v12.7h-5.178v-21.02h5.329l8.129 12.102v-12.102h5.208v21.02h-4.998zM61.604 140.185H56v-4.727h16.333v4.727H66.76v16.292h-5.155zM84.015 138.92c-3.27 0-5.36 2.56-5.36 5.88 0 3.29 2.09 5.88 5.36 5.88 3.24 0 5.33-2.59 5.33-5.88 0-3.32-2.09-5.88-5.33-5.88m0-4.63c6.008 0 10.485 4.326 10.485 10.51s-4.477 10.51-10.485 10.51c-6.009 0-10.515-4.326-10.515-10.51s4.506-10.51 10.515-10.51M111.602 148.725h5.463l-2.746-8.54-2.717 8.54zm6.777 4.57h-8.09l-.994 3.182h-5.462l7.331-21.019h6.31l7.36 21.02h-5.462l-.993-3.183zM138.178 143.778v12.7H133v-21.02h5.329l8.129 12.102v-12.102h5.209v21.02h-4.998zM156.333 135.458h14v4.57h-9.075v3.498h8.875v4.6h-8.875v3.782h9.075v4.57h-14zM185.514 143.305l-2.985 13.172h-5.383l-5.646-21.019h5.706l2.986 14.023 3.371-14.023h3.903l3.371 14.023 2.957-14.023h5.706l-5.618 21.02H188.5zM218.306 140.028h-4.183v4.727h4.183c1.384 0 2.553-.914 2.553-2.364 0-1.45-1.169-2.363-2.553-2.363zm-1.476 9.327h-2.707v7.122h-5.29v-21.019h10.273c4.552 0 7.135 3.088 7.135 6.996 0 3.656-2.184 5.61-4.09 6.303l4.182 7.72h-6.059l-3.444-7.122zM229.833 156.477h4.667v-21.019h-4.667zM246.154 151.75c3.407 0 5.387-2.678 5.387-5.798 0-3.246-1.806-5.767-5.358-5.767h-3.174v11.565h3.145zM238 135.458h8.154c6.115 0 10.513 3.97 10.513 10.494 0 6.523-4.398 10.525-10.484 10.525H238v-21.019zM259 135.458h14v4.57h-9.076v3.498h8.876v4.6h-8.876v3.782H273v4.57h-14z"
          />
        </g>
      </svg>
    )
})

EventLogo.defaultProps = {
    x: 0,
    y: 0,
    width: '215px',
    height: '122px'
}

export default withTheme(themes)(EventLogo)
