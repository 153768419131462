import * as React from 'react'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes'

function VoucherEventLogo (props) {
    // const { x, y } = props
    // const { width, height } = props
    // const { theme } = props

    return (
      <svg
      width={196}
      height={118}
      viewBox="0 0 196 118"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>{"EVENT_LOGO"}</title>
        <defs>
          <path id="prefix__voucher_a" d="M0 .248h195.936v116.99H0z" />
          <path id="prefix__voucher_c" d="M0 117.239h196V.76H0z" />
        </defs>
        <g transform="translate(0 .702)" fill="none" fillRule="evenodd">
          <mask id="prefix__voucher_b" fill="#fff">
            <use xlinkHref="#prefix__voucher_a" />
          </mask>
          <path
            d="M17.099.248C7.67.248 0 7.941 0 17.397v82.693c0 9.456 7.67 17.149 17.099 17.149h161.738c9.429 0 17.1-7.693 17.1-17.15V17.398c0-9.456-7.671-17.149-17.1-17.149H17.1z"
            fill="#FEFEFE"
            mask="url(#prefix__voucher_b)"
          />
          <path
            d="M179.041 109.206H16.96c-5.355 0-9.695-4.337-9.695-9.687V79.946h181.472V99.52c0 5.35-4.34 9.687-9.695 9.687m0-104.638H16.96c-6.838 0-12.4 5.558-12.4 12.39V99.52c0 6.832 5.562 12.39 12.4 12.39H179.04c6.838 0 12.4-5.558 12.4-12.39v-82.56c0-6.833-5.562-12.391-12.4-12.391"
            fill={props.primaryColor}
          />
          <path
            d="M20.663 67.268c-2.127-2.355-3.19-6.152-3.19-11.39v-5.115H27.84v6.516c0 2.6.838 3.898 2.516 3.898.94 0 1.595-.274 1.963-.822.369-.548.552-1.471.552-2.771 0-1.705-.205-3.116-.613-4.233-.41-1.116-.931-2.05-1.565-2.801-.634-.751-1.77-1.919-3.405-3.502l-4.54-4.507c-3.518-3.41-5.276-7.187-5.276-11.328 0-4.466 1.032-7.866 3.098-10.201 2.065-2.335 5.082-3.502 9.05-3.502 4.744 0 8.16 1.248 10.246 3.745 2.086 2.497 3.129 6.405 3.129 11.724H32.259l-.061-3.593c0-.69-.195-1.238-.583-1.645-.39-.405-.931-.609-1.626-.609-.819 0-1.432.224-1.84.67-.41.448-.614 1.057-.614 1.828 0 1.705.981 3.47 2.944 5.298l6.136 5.847c1.431 1.38 2.617 2.69 3.559 3.928.94 1.239 1.696 2.7 2.27 4.385.572 1.685.858 3.685.858 5.999 0 5.157-.95 9.065-2.853 11.724-1.901 2.66-5.041 3.989-9.417 3.989-4.785 0-8.243-1.177-10.369-3.532M58.162 39.769c1.276 0 2.214-.234 2.811-.703s.989-1.141 1.174-2.018c.186-.877.279-2.13.279-3.761 0-2.202-.248-3.792-.742-4.77-.494-.979-1.483-1.468-2.966-1.468h-1.174v12.72h.618zM47.1 17.509h14.027c4.242 0 7.27 1.356 9.083 4.067 1.812 2.712 2.72 6.676 2.72 11.894 0 5.22-.804 9.153-2.41 11.802-1.607 2.651-4.491 3.975-8.652 3.975h-4.325v20.792H47.1v-52.53zM89.253 36.956c1.476 0 2.214-1.59 2.214-4.77 0-1.385-.062-2.425-.185-3.119-.123-.692-.349-1.171-.676-1.437-.329-.265-.8-.397-1.415-.397h-2.46v9.723h2.522zM75.969 17.51h16.236c2.582 0 4.581.58 5.996 1.742 1.414 1.162 2.368 2.793 2.86 4.892.491 2.1.737 4.78.737 8.042 0 2.976-.39 5.3-1.168 6.971-.78 1.672-2.132 2.834-4.06 3.486 1.6.327 2.758 1.122 3.476 2.385.716 1.264 1.076 2.976 1.076 5.136l-.123 19.875H90.667V49.492c0-1.468-.287-2.405-.861-2.813-.575-.407-1.62-.611-3.136-.611v23.97H75.969V17.51z"
            fill="#FEFEFE"
          />
          <mask id="prefix__voucher_d" fill="#fff">
            <use xlinkHref="#prefix__voucher_c" />
          </mask>
          <path
            fill="#FEFEFE"
            mask="url(#prefix__voucher_d)"
            d="M105.597 70.039h11.395v-52.53h-11.395zM121.55 70.039v-52.53h10.834l4.835 25.134V17.51h10.16v52.529h-10.282l-5.264-26.295v26.295zM155.456 66.81c-1.84-2.658-2.758-6.688-2.758-12.088V32.187c0-4.831 1.07-8.485 3.213-10.962 2.142-2.476 5.336-3.715 9.58-3.715 3.435 0 6.103.69 8.003 2.07 1.9 1.381 3.214 3.39 3.942 6.03.727 2.64 1.091 6.029 1.091 10.17h-10.186v-4.689c0-1.258-.172-2.233-.516-2.923-.343-.69-.96-1.036-1.849-1.036-1.98 0-2.971 1.3-2.971 3.898v25.336c0 1.583.202 2.781.607 3.593.403.813 1.11 1.218 2.122 1.218 1.01 0 1.717-.405 2.122-1.218.404-.812.606-2.01.606-3.593V44.552l-2.79-4.691h12.734v30.452h-4.184l-1.758-4.385c-1.86 3.248-4.73 4.872-8.61 4.872-3.76 0-6.559-1.33-8.398-3.99"
          />
          <path
            fill="#000"
            mask="url(#prefix__voucher_d)"
            d="M18.233 102.013h3.038V88.31h-3.038zM26.923 93.734v8.279H23.55V88.31h3.47l5.294 7.889v-7.89h3.391v13.704h-3.254zM40.114 91.391h-3.649V88.31h10.636v3.081h-3.63v10.622h-3.357zM54.707 90.567c-2.128 0-3.49 1.668-3.49 3.833 0 2.145 1.362 3.833 3.49 3.833 2.11 0 3.471-1.688 3.471-3.833 0-2.165-1.36-3.833-3.47-3.833m0-3.019c3.912 0 6.827 2.82 6.827 6.852s-2.915 6.852-6.828 6.852c-3.912 0-6.847-2.82-6.847-6.852s2.935-6.852 6.847-6.852M72.671 96.959h3.557L74.44 91.39l-1.769 5.568zm4.413 2.979h-5.269l-.646 2.075h-3.557l4.774-13.703h4.108l4.793 13.703H77.73l-.646-2.075zM89.976 93.734v8.279h-3.371V88.31h3.47l5.293 7.889v-7.89h3.392v13.704h-3.255zM101.798 88.31h9.117v2.979h-5.91v2.28h5.78v3h-5.78v2.465h5.91v2.979h-9.117zM120.8 93.425l-1.944 8.588h-3.505l-3.677-13.703h3.716l1.944 9.142 2.195-9.142h2.542l2.195 9.142 1.925-9.142h3.716l-3.658 13.703h-3.505zM142.153 91.289h-2.724v3.081h2.724c.9 0 1.662-.595 1.662-1.54 0-.946-.761-1.541-1.662-1.541zm-.962 6.08h-1.762v4.644h-3.445V88.31h6.69c2.964 0 4.646 2.013 4.646 4.56 0 2.384-1.422 3.658-2.664 4.11l2.724 5.033h-3.946l-2.243-4.643zM149.659 102.013h3.039V88.31h-3.04zM160.286 98.931c2.219 0 3.508-1.746 3.508-3.78 0-2.116-1.176-3.76-3.489-3.76h-2.067v7.54h2.048zm-5.31-10.621h5.31c3.982 0 6.846 2.588 6.846 6.84 0 4.254-2.864 6.863-6.827 6.863h-5.328V88.31zM168.651 88.31h9.116v2.979h-5.91v2.28h5.78v3h-5.78v2.465h5.91v2.979h-9.116z"
          />
        </g>
      </svg>
    )
}

VoucherEventLogo.defaultProps = {
    x: 0,
    y: 0,
    width: '325px',
    height: '219px'
}

export default withTheme(themes)(VoucherEventLogo)
