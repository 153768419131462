// @flow
import * as React from 'react'
import { useTransition, animated } from 'react-spring'
import countdown from 'countdown'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

const END_DATE = new Date(new Date().getFullYear(), 10, 27)

function Digits ({
    input = '00',
    xsmall
}) {
    const [digits, setDigits] = React.useState([])

    React.useEffect(() => {
        let chain = `${parseInt(input)}`.trim()

        if (chain.length === 1) {
            chain = `0${chain}`
        }

        const digits = chain.split('')

        setDigits(
            [
                {
                    digit: digits[0],
                    index: 0,
                    key: `${digits[0]}-a`
                },
                {
                    digit: digits[1],
                    index: 1,
                    key: `${digits[1]}-b`
                }
            ]
        )
    }, [input])

    const transitions = useTransition(digits, digit => digit.key, {
        initial: {
            transform: xsmall ? 'translate3d(0px, -5px, 20px) rotateX(-35deg) skew(5deg, 2deg)' : 'translate3d(0px, -25px, 50px) rotateX(-95deg) skew(5deg, 2deg)',
            opacity: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.42)'
        },
        from: {
            transform: xsmall ? 'translate3d(0px, 0px, 50px) rotateX(-95deg) skew(5deg, 2deg)' : 'translate3d(0px, -20px, 50px) rotateX(-95deg) skew(5deg, 2deg)',
            opacity: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.32)'
        },
        enter: {
            transform: 'translate3d(0, 0px, 0px) rotateX(0deg) skew(0deg, 0deg)',
            opacity: 1,
            backgroundColor: 'rgba(255, 255, 255, 0)'
        },
        leave: {
            transform: xsmall ? 'translate3d(0px, 5px, 20px) rotateX(-35deg) skew(5deg, 2deg)' : 'translate3d(0px, 20px, -50px) rotateX(95deg) skew(-5deg, -2deg)',
            opacity: 0,
            backgroundColor: 'rgba(255, 255, 255, 0)'
        },
        unique: true,
        reset: true
    })

    return transitions.map(({ item, props, key }) => (
        <animated.div
            className={styles[`digit${item.index}`]}
            style={props}
            key={key}
        >
            {item.digit}
        </animated.div>
    ))
}

function Timer ({
    theme,
    xsmall,
    small,
    date
}) {
    const getDiff = React.useCallback(() => {
        const current = countdown(new Date(), (date || END_DATE), countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS)
        return {
            days: current.days,
            hours: current.hours,
            minutes: current.minutes,
            seconds: current.seconds
        }
    }, [date])

    const [ intervalDate, setIntervalDate ] = React.useState(getDiff())

    React.useEffect(() => {
        const interval = window.setInterval(() => {
            setIntervalDate(getDiff())
        }, 1000)

        return () => {
            window.clearInterval(interval)
        }
    }, [ getDiff ])

    return (
        <div className={cn(styles.timer, {
            [styles.small]: small,
            [styles.xsmall]: xsmall
        })} style={{
            '--text-color': theme.textColor
        }}>
            <div className={cn(styles.timerBox, styles.timerBoxCellA)}>
                <span className={styles.time}>
                    <Digits xsmall={xsmall} input={intervalDate.days} />
                </span>
                <span className={styles.dateValue}>DAYS</span>
            </div>
            <div className={cn(styles.timerBox, styles.timerBoxCellB)}>
                <span className={styles.time}>
                    <Digits xsmall={xsmall} input={intervalDate.hours} />
                </span>
                <span className={styles.dateValue}>HRS</span>
            </div>
            <div className={cn(styles.timerBox, styles.timerBoxCellC)}>
                <span className={styles.time}>
                    <Digits xsmall={xsmall} input={intervalDate.minutes} />
                </span>
                <span className={styles.dateValue}>MINS</span>
            </div>
            <div className={cn(styles.timerBox, styles.timerBoxCellD)}>
                <span className={styles.time}>
                    <Digits xsmall={xsmall} input={intervalDate.seconds} />
                </span>
                <span className={styles.dateValue}>SECS</span>
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(Timer)
