/* eslint-disable react-hooks/exhaustive-deps */
// @flow

import React from 'react'
import { withTranslation } from 'react-i18next'

import withTheme from 'hoc/withTheme'
import { getFallbackFromSegment } from '../../util/getVehicleImageUrl'
import supportedThemes from './themes/__supportedThemes.js'
import transDomain from './translations/index.translations'
import cn from 'classnames'
import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store'
import styles from './styles.module.scss'

import SEDAN_FALLBACK from './images/fallback/Sedan.png'
import SUV_FALLBACK from './images/fallback/SUV & Crossover.png'
import TRUCK_FALLBACK from './images/fallback/Truck.png'

const LOADER = require('./images/loader.svg')

function ImageFallback ({ segment }) {
    let url = null
    switch (segment) {
        case 'Truck':
            url = TRUCK_FALLBACK
            break
        case 'SUV & Crossover':
            url = SUV_FALLBACK
            break
        case 'Car/Sedan':
            url = SEDAN_FALLBACK
            break
        default:
            url = SEDAN_FALLBACK
    }
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img style={{ marginTop: 'auto' }} src={url} />
}

const ShoppingCard = ({
    model,
    onClick = () => {},
    buttonText = 'Select',
    buttonAction = 'submit',
    voucherValue,
    startingPrice,
    xl,
    theme,
    isVisible,
    customPrimaryColor,
    customSecondaryColor,
}) => {
    const [loading, setLoading] = React.useState(false)
    const [imageError, setImageError] = React.useState(false)
    let [loaded, setLoaded] = React.useState(false)
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)

    const handleImageLoad = React.useCallback(() => {
        setLoaded(true)
    }, [])

    const handleImageError = React.useCallback(() => {
        setImageError(true)
        setLoaded(true)
    }, [])

    const src = React.useMemo(() => {
        if (model.segment === 'Pre-Owned') {
            handleImageError(true)

            if (['GMC'].includes(model.make)) {
                return TRUCK_FALLBACK
            }
            if (['FORD'].includes(model.make)) {
                return SUV_FALLBACK
            }
            return SEDAN_FALLBACK
        }

        return model.images.front_34.m || getFallbackFromSegment(model.segment)
    }, [model, voucherValue])

    const handleOnClick = React.useCallback(() => {
        setLoading(true)
        onClick(null)
    }, [])


    const title = `${model.year || ''} ${model.name} ${model.segment === 'Pre-Owned' ? model.make : ''}`

    return (
        <div
            className={cn(styles.container, {
                [styles.xl]: xl,
                [styles.isVisible]: isVisible,
                [styles.imageFallback]: imageError
            })}
            style={{
                '--title-color': theme.titleColor,
                '--tag-background': theme.tagBackground
            }}
        >
            <span className={styles.voucherTag}>
                Voucher: {currencyFormatter.format(voucherValue)}
            </span>
            <div
                className={cn(styles.wrapper, styles.carContainer, {
                    [styles.loaded]: loaded,
                    [styles.largeTitle]: title.length >= 35
                })}
      >
                {imageError ? (
                    <ImageFallback segment={model.segment} />
                ) : (
                    <>
                        <Loader color={theme.loaderColor} />
                        <img
                            src={src}
                            alt={`${model.year} ${model.name}`}
                            onLoad={handleImageLoad}
                            onError={handleImageError}
            />
                    </>
                )}
            </div>
            <div className={styles.info}>
                <span
                    className={styles.title}
                    style={{
                        color: customPrimaryColor || theme.titleColor
                    }}
                >
                    {title}
                </span>
                {startingPrice && (
                <span className={styles.value}>
                    Starting at {currencyFormatter.format(startingPrice)}
                </span>
                )}
            </div>
            <div
                onClick={handleOnClick}
                className={cn(styles.button, {
                    [styles.isLoading]: loading
                })}
                type={buttonAction}
                style={{
                    '--color-loader': `url(${LOADER})`,
                    '--primary-color': customPrimaryColor || theme.primaryColor,
                    '--secondary-color': customSecondaryColor || theme.secondaryColor
                }}
      >
                {buttonText}
            </div>
        </div>
    )
}

const Loader = (props: { color: string }) => {
    return (
        <div className={styles.loader} style={{ borderTopColor: props.color }} />
    )
}

export default withTranslation(transDomain)(
    withTheme(supportedThemes)(ShoppingCard)
)
