
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns0422854f7fe5a36b63a7aa2f0eba87bben_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '0422854f7fe5a36b63a7aa2f0eba87bb', flattenObject(ns0422854f7fe5a36b63a7aa2f0eba87bben_US), true, true);
        
            import ns0422854f7fe5a36b63a7aa2f0eba87bben from './en.yaml'
            i18n.addResourceBundle('en', '0422854f7fe5a36b63a7aa2f0eba87bb', flattenObject(ns0422854f7fe5a36b63a7aa2f0eba87bben), true, true);
        
        export default '0422854f7fe5a36b63a7aa2f0eba87bb';
    