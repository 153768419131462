export function getFallbackFromSegment(segment) {
    switch (true) {
        case /SUV/i.test(segment):
            return 'suv'
        case /pickup/i.test(segment):
        case /truck/i.test(segment):
            return 'truck'
        default:
            return 'sedan'
    }
}
