// @flow
import * as React from 'react'
import RegistrationForm from 'components/RegistrationForm'
import MediaQuery from 'react-responsive'

// import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import styles from './styles.module.scss'

function RegisterForm (props) {
    return (
        <div className={styles.registerForm} style={{
            '--placeholder-color': props.theme.placeholderColor
        }}>
            <div className={styles.formContainer}>
                <div
                    className="register-text-masked-layers js-register-text-masked-layers"
                >
                    <h3 className="register-text-layer js-masked-layer" style={{ color: props.theme.titleColor }}>
                        <Trans data-mask="right" i18nKey="title">
                            <MediaQuery minWidth={1024}>
                                <strong className={styles.title}>
                                    Spring into <br /> a new vehicle <br /><span style={{ color: props.theme.primary }}>and Save!</span>
                                </strong>
                            </MediaQuery>
                            <MediaQuery maxWidth={1023} minWidth={768}>
                                <strong className={styles.title}>
                                    Spring into a new <br /> vehicle and Save!
                                </strong>
                            </MediaQuery>
                            <MediaQuery maxWidth={767}>
                                <strong className={styles.title}>
                                    Spring Into A New <br /> Ride And Save!
                                </strong>
                            </MediaQuery>
                        </Trans>
                    </h3>
                </div>

                <RegistrationForm />
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(RegisterForm))
