// @flow
import * as React from 'react'
import cn from 'classnames'
import { Store } from 'Store.js'
import { withTranslation, Trans } from 'react-i18next'
import { Currency, Percent } from 'i18n/NumberFormatter'

import withTheme from 'hoc/withTheme'

import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

function Sidebar (props) {
    const { t, theme, className, background } = props
    const { state } = React.useContext(Store)
    const currencyFormatter = Currency(state.currency, state.locale)
    const voucher = state.campaign.incentives.voucher
    const incentives = state.campaign.incentives.manufacturer
    const hiddenCashBack = state.campaign.incentives?.manufacturer?.cashBack === 0
    const hiddenInterestRate = theme.hiddenInterestRate

    return (
        <div className={cn(styles.container, className)} style={{
            backgroundColor: theme.backgroundColor,
            '--label-color': theme.valueColor
        }}>
            <div
                style={{
                    backgroundImage: `url(${theme.background[background]})`
                }}
                className={styles.sidebarImage}
            />

            <div className={cn(styles.body, {
                [styles.withNoIncentives]: !incentives
            })}>
                <div className={styles.sidebarVoucherValue}>
                    <span className={styles.title}
                        style={{
                            color: theme.titleColor
                        }}>
                        {t('title', {
                            voucher: state.campaign.incentives.voucher.name,
                            type: state.campaign.incentives.voucher.type
                        })}
                    </span>
                    <span className={styles.value}
                        style={{ color: theme.voucherValueColor }}>
                        {currencyFormatter.format(voucher.value)}
                    </span>
                </div>

                {incentives && (
                    <span className={styles.cashbackAndRatesSepartor} style={{
                        '--primary-color': theme.primaryColor
                    }} />
                )}

                {incentives && (
                    <div className={cn(styles.cashbackAndRates, {
                        [styles.hiddenCashBack]: hiddenCashBack,
                        [styles.areCombinable]: incentives.areCombinable,
                        [styles.hiddenInterestRate]: hiddenInterestRate,
                    })}>

                        <div className={styles.cashBack}>
                            <span className={styles.cashBackTitle} style={{ color: theme.cashBackTitleColor }}>
                                <Trans i18nKey="cash_back">
                                    Combine with <strong>cash incentives</strong> up to
                                </Trans>
                            </span>
                            <span className={cn(styles.cashBackValue, { [styles.smallValues]: props.small })} style={{ color: theme.valueColor }}>
                                {currencyFormatter.format(incentives.cashBack)}
                            </span>
                        </div>

                        {!hiddenInterestRate && (
                            <div className={styles.rates} style={{
                                backgroundColor: theme.ratesBg
                            }}>
                                <span className={styles.ratesTitle} style={{ color: theme.ratesTitleColor }}>
                                    <Trans i18nKey="rates">
                                        Don't forget about our <strong>rates as low as</strong>
                                    </Trans>
                                </span>
                                <span className={cn(styles.ratesValue, { [styles.smallValues]: props.small })} style={{ color: theme.valueColor }}>
                                    {Percent(state.locale).format((Number.parseFloat(incentives.interestRate).toPrecision(3) / 100) || 0)}
                                </span>
                            </div>
                        )}

                    </div>
                )}
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Sidebar))
