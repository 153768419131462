
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns4611f5410aeaf38c6c15834a60296596en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '4611f5410aeaf38c6c15834a60296596', flattenObject(ns4611f5410aeaf38c6c15834a60296596en_US), true, true);
        
            import ns4611f5410aeaf38c6c15834a60296596en from './en.yaml'
            i18n.addResourceBundle('en', '4611f5410aeaf38c6c15834a60296596', flattenObject(ns4611f5410aeaf38c6c15834a60296596en), true, true);
        
        export default '4611f5410aeaf38c6c15834a60296596';
    