// @flow
import * as React from 'react'

import Header from 'components/Header'
import LeadGenForm from 'components/LeadGenForm/Step1'
import Sidebar from 'components/Sidebar'
import Steps from 'components/Steps'
import MediaQuery from 'react-responsive'

import { Store } from 'Store'
import { updateLead } from 'Actions'

import { Redirect } from 'react-router-dom'

import styles from './styles.module.scss'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-1', '')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-1', 'step-2')}${props.location.search}`} push />
    }
    return (
        <>
            <Header />

            <div className={styles.layoutWrapper}>
                <div className={styles.layout}>
                    <MediaQuery maxWidth={1023}>
                        <Steps step={1} />
                    </MediaQuery>

                    <div className={styles.sidebar}>
                        <Sidebar background={0} />
                    </div>

                    <div className={styles.step}>
                        <MediaQuery minWidth={1024}>
                            <Steps step={1} />
                        </MediaQuery>

                        <div className={styles.container}>
                            <div className={styles.formContainer}>
                                <LeadGenForm
                                    onSubmit={async (data, done) => {
                                        try {
                                            let lead = {
                                                fields: data,
                                                isComplete: false
                                            }
                                            await updateLead(dispatch, state.code, lead)
                                            setSubmitted(true)
                                        } catch (err) {
                                            alert('Oops something went wrong')
                                            console.error(err)
                                            done(err)
                                        }
                                    }}
                                    initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
